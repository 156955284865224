<template>
  <div class="header py4">
    <div class="container">
      <div class="header-body text-center">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-6">
            <div class="top-header" v-if="step == 1">
              <h1 class="py-2 unset-margin"></h1>
                <ul class="text-lead text-white">
                  <li> Es geht los!</li>
                  <li>Unsere Erklärvideos und Fotos führen dich sorgfältig durch den Abdruckprozess</li>
                  <li>So können wir deine maßgefertigten Einlagen optimal auf dich anpassen</li>
                </ul>

            </div>
            <div class="top-header" v-if="step == 2">
              <h1 class="py-2 unset-margin"></h1>
              <ul class="text-lead text-white" ref="formSix">
                <li>Abdruckset vollständig?</li>
                <li>VORSICHT mit dem grünen Klappfolder, darunter befindet sich der empfindliche Trittschaum</li>
              </ul>
            </div>
            <div class="top-header" v-if="step == 3">
              <h1 class="text-center text-white py-2 unset-margin">1/4</h1>
              <ul class="text-lead text-white" ref="formThree">
                <li>Standanalyse per Fotos</li>
                <li>Ganganalyse per Video</li>
              </ul>
            </div>
            <div class="top-header" v-if="step == 4">
              <h1 class="text-center text-white py-2 unset-margin">1/4</h1>
              <ul class="text-lead text-white">
                <li>Einlegesohle abfotografieren</li>
                <li>Für optimale Passform in deinen Schuhen</li>
              </ul>

            </div>
            <div class="top-header" v-if="step == 5">
              <h1 class="text-center text-white py-2 unset-margin">2/4</h1>

              <ul class="text-lead text-white" ref="formThree">
                <li>Gewicht, Größe oder etwaige Vorerkrankungen sind wichtig</li>
                <li>Für ein perfektes Ergebnis, verrate uns ein paar Details von dir</li>
              </ul>
            </div>
            <div class="top-header" v-if="step == 6">
              <h1 class="text-center text-white py-2 unset-margin">3/4</h1>
              <ul class="text-lead text-white" ref="formThree">
                <li>Fußabdrücke auf dem Kohlepapier (Blauabdruck)</li>
                <li>Analysiert deine Fußstellung sowie Druck- und Belastungspunkte</li>
              </ul>

            </div>
            <div class="top-header" v-if="step == 7">
              <h1 class="text-center text-white py-2 unset-margin">4/4</h1>
              <ul class="text-lead text-white" ref="formThree">
                <li>3D Abdruck mit unserem Trittschaum</li>
                <li>Analysiert deine genaue Fußform</li>
              </ul>
            </div>
            <div class="top-header" v-if="step == 8" ref="formSeven">
              <h1 class="text-center text-white py-2 unset-margin"></h1>
              <ul class="text-lead text-white">
                <li>geschafft!</li>
                <li>Einpacken & abschicken</li>
                <li>deine individuellen Schuheinlagen werden schon bald für dich maßgefertigt</li>
              </ul>

            </div>
            <div v-if="step == 9" ref="formEight">
              <h2 class="text-white"><i>Du warst großartig!</i></h2>
              <p class="text-lead text-white last-message">
                Vielen Dank für deine sorgfältige Abdrucknahme! Nun sind wieder
                wir an der Reihe.<br />
                Sobald dein Abdruckset in unserer Werkstatt angekommen ist,
                schicken wir dir ein Update.<br />
                Schon bald machen sich deine maßgefertigen Schuheinlagen auf den
                Weg zu dir.<br />
              </p>
              <div class="row">
                <div class="col-12 text-center">
                  <a
                    v-if="step == 9"
                    href="http://fyss.com/"
                    class="my-2 btn btn-md btn-previous"
                  >
                    schließen
                  </a>
                </div>
              </div>
            </div>
            <base-alert type="danger" dismissible v-if="error">
              <span class="alert-inner--text"
                ><strong>Error!</strong> Something went wrong!</span
              >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                @click.prevent="closeError"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" v-bind:style="{ display: display }">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-2 py-lg-2">
          <form role="form" autocomplete="off">
             <div class="loader" v-if="loading">
              </div>
            <!-- Form 1st  start-->
            <section v-if="step == 1">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Deine Bestelldaten</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="text-justify">
                    Damit wir deine Fußabdrücke und Daten deinem Profil zuordnen
                    können, benötigen wir deine E-Mail Adresse (unter der du
                    bestellt hast) sowie Bestellnummer.
                  </p>
                </div>
                <div class="col-12">
                  <label> deine E-Mail Adresse </label>
                  <base-input
                    type="email"
                    placeholder="deine E-Mail Adresse"
                    formClasses="input-group-alternative"
                    v-model="formOne.email"
                  >
                  </base-input>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmit && missingEmail"
                  >
                    Bitte gib deine E-Mail Adresse an
                  </div>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmit && existingEmail"
                  >
                    This email already exsist!
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <label>
                    deine Bestellnummer (steht auch am Versandetikett)</label
                  >
                  <base-input
                    placeholder="deine Bestellnummer"
                    formClasses="input-group-alternative"
                    v-model="formOne.orderNumber"
                  >
                  </base-input>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmit && missingOrderNumber"
                  >
                    Bitte gib deine Bestellnummer an
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="formOne.terms"
                    @click="termsCheck"
                  />
                  <span class="pl-2 terms"
                    >Ich gebe meine <a href="#" @click="consentModal=true">datenschutzrechtliche Einwilligung</a> zur Verarbeitung meiner Daten im Zusammenhang mit der Anfertigung meiner Schuheinlage(en)</span
                  >
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmit && missingTerms"
                  >
                    Bitte bestätige die Datenschutzerklärung
                  </div>
                </div>
              </div>
            </section>
             <modal v-model:show="consentModal">
                <template v-slot:header>
                </template>
                <h4 style="text-align:center;margin-top:-40px">DATENSCHUTZRECHTLICHE EINWILLIGUNGSERKLÄRUNG </h4>
                <h5 style="text-align:center">gem Art 9 Abs 2 lit a DSGVO </h5>
                <p>
                  Ich willige ein, dass die folgenden personenbezogenen Daten, 
                  von mir hochgeladene Fotos, das von mir hochgeladene Gangvideo, mein Trittschaumabdruck,
                   mein Blauabdruck sowie die von mir allenfalls angegebenen körperlichen Beschwerden, Diagnosen und schmerzenden Körperstellen samt etwaiger Notizen (dies sind Gesundheitsdaten, die freiwillig angegeben werden können) zum Zweck der Anfertigung der bestellten Schuheinlagen von der fyss GmbH, Voitgasse 6, 1220 Wien, verarbeitet werden. 
                </p>
                <p>Diese Einwilligung kann jederzeit per E-Mail unter <a href="mailto:datenschutz@fyss.com">datenschutz@fyss.com</a> widerrufen werden. Durch den Widerruf wird 
                  die Rechtmäßigkeit der bis zum Zeitpunkt des Widerrufs erfolgten Datenverarbeitung nicht berührt.</p>

                <template v-slot:footer>
                    <base-button type="link" class="ml-auto" @click="consentModal = false">Close
                    </base-button>
                </template>
            </modal>
            <!-- Form 1 End-->

            <!-- Form 2 Start-->
            <section v-if="step==2">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Dein Abdruckset</i></h2>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <!-- <p class="">VORSICHT – entnimm den grünen Klappfolder bitte vorsichtig, da sich darunter der empfindliche Trittschaum befindet. </p> -->
                  <figure class="media">
                    <video
                      preload="metadata"
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v1.jpg"
                    >
                      <source
                        src="../assets/videos/1_Abdruckset_Inhalt.mp4"
                        type="video/mp4"
                      />

                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
              </div>

              <div class="row my-2">
                <span class="ml-3 pb-2"
                  >Folgende Dinge sollten in deinem Abdruckset enthalten
                  sein:</span
                >
                <div class="col-12">
                  <ul class="list-unstyled">
                    <li>
                      <ul>
                        <li>
                          <span
                            >Retouretikett zum kostenlosen Rückversand</span
                          >
                          <el-popover
                            placement="top"
                            title=""
                            :width="250"
                            trigger="hover"
                          >
                            <img src="../assets/images/p1.jpg" />
                            <template #reference>
                              <span class="ml-2 info-display"
                                ><img src="../assets/info.png" />
                              </span>
                            </template>
                          </el-popover>
                        </li>
                        <li>
                          <span
                            >Klappfolder mit Raster und 2x Blauabdruck</span
                          >
                          <el-popover
                            placement="top"
                            title=""
                            :width="250"
                            trigger="hover"
                          >
                            <img src="../assets/images/p2.jpg" />
                            <template #reference>
                              <span class="ml-2 info-display"
                                ><img src="../assets/info.png" />
                              </span>
                            </template>
                          </el-popover>
                        </li>
                        <li>
                          <span>Faltbarer Handyhalter</span>
                          <el-popover
                            placement="top"
                            title=""
                            :width="250"
                            trigger="hover"
                          >
                            <img src="../assets/images/p3.jpg" />
                            <template #reference>
                              <span class="ml-2 info-display"
                                ><img src="../assets/info.png" />
                              </span>
                            </template>
                          </el-popover>
                        </li>
                        <li>
                          <span>Trittschaum</span>
                          <el-popover
                            placement="top"
                            title=""
                            :width="250"
                            trigger="hover"
                          >
                            <img src="../assets/images/p4.jpg" />
                            <template #reference>
                              <span class="ml-2 info-display"
                                ><img src="../assets/info.png" />
                              </span>
                            </template>
                          </el-popover>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="col-12">
                  <span class="pt-2">
                    Sollte dir etwas in deinem Abdruckset fehlen, kontaktiere
                    bitte unseren
                    <a
                      :href="`mailto:help@fyss.com?subject=Order Number #${formOne.orderNumber}`"
                      >Support</a
                    ></span
                  >
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12 text-center">
                  <h3>Bereite dich vor</h3>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.timeCheck"
                    @change="timeCheck"
                  />
                  <span class="pl-2"> ich habe nun ca. 15-20 Minuten für mein Abdruckset Zeit</span>
                  <div
                    class="form-control-feedback"
                    v-if="attemptImpressionSet && missingTimeCheck"
                  >
                    Bitte nimm dir ausreichend Zeit für dein Abdruckset
                  </div>
                </div>

                <div class="col-12 my-2">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.bareFootCheck"
                    @change="bareFootCheck"
                  />
                  <span class="pl-2">ich habe eine kurze Hose oder enge Leggins an und bin barfuß</span>
                  <div
                    class="form-control-feedback"
                    v-if="attemptImpressionSet && missingbareFootCheck"
                  >
                    Bitte sorge dafür, dass man deine Füße und Knie jederzeit gut erkennen kann
                  </div>
                </div>

                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.backgroundCheck"
                    @change="backgroundCheck"
                  />
                  <span class="pl-2">Mein Smartphone und Webbrowser erlauben das kurzfristige Speichern von Websitedaten (Cookies)</span>
                  <div
                    class="form-control-feedback"
                    v-if="attemptImpressionSet && missingCookiesCheck"
                  >
                    Bitte erlauben Sie Cookies
                  </div>
                </div>

              </div>

            </section>
            <!-- Form 2 End-->

            <!-- Form 3 Start-->
            <section v-if="step == 5">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Das bin ich</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>
                    Um auf deine individuellen Bedürfnisse eingehen zu können,
                    beantworte uns die folgenden körperspezifischen Fragen
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <opened-accordion class="mb-2" ref="AppAccordion" >
                    <template v-slot:title>
                      <span class="font-semibold text-l"
                        >Persönliche Angaben</span
                      >
                    </template>
                    <template v-slot:content>
                      <div class="row">
                        <div class="col-md-6">
                          <label> Geschlecht</label>
                          <select class="form-control" v-model="formSix.gender">
                            <option>Auswählen</option>
                            <option value="Female">Weiblich</option>
                            <option value="Male">Männlich</option>
                            <option value="Other">X</option>
                          </select>
                          <div
                            class="form-control-feedback"
                            v-if="attemptSubmitSix && missingGender"
                          >
                            Bitte gib dein Geschlecht an
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label">Geburtstag</label>
                          <!-- <input
                            type="text"
                            placeholder="Tag/Monat/Jahr"
                            onfocus="(this.type='date')"
                            class="form-control"
                            v-model="formSix.birthday"
                          /> -->
                          <flat-pickr  
                            type="text"
                            placeholder="Tag/Monat/Jahr"
                            :config="config"
                            autocomplete="off"
                            class="form-control"
                            v-model="formSix.birthday"></flat-pickr>

                          <div
                            class="form-control-feedback"
                            v-if="attemptSubmitSix && missingBirthday"
                          >
                            Bitte gib dein Geburtsdatum an
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label"> Schuhgröße</label>
                          <select
                            class="form-control"
                            v-model="formSix.shoeSize"
                          >
                            <option>Auswählen</option>
                            <option
                              v-for="size in sizes"
                              :key="size"
                              :value="size"
                            >
                              EU-{{ size }}
                            </option>
                          </select>
                          <div
                            class="form-control-feedback"
                            v-if="attemptSubmitSix && missingShoeSize"
                          >
                            Bitte gib deine Schuhgröße an
                          </div>
                        </div>

                        <div class="col-md-6">
                          <label class="form-label">Körpergröße (in cm)</label>
                          <input
                            type="number"
                            class="form-control"
                            v-model="formSix.height"
                            step="0.01"
                          />
                          <div
                            class="form-control-feedback"
                            v-if="attemptSubmitSix && missingHeight"
                          >
                            Bitte gib deine Körpergröße an
                          </div>
                        </div>

                        <div class="col-md-6">
                          <label class="form-label"
                            >Körpergewicht (in kg)</label
                          >
                          <input
                            type="number"
                            class="form-control"
                            v-model="formSix.weight"
                          />
                          <div
                            class="form-control-feedback"
                            v-if="attemptSubmitSix && missingWeight"
                          >
                            Bitte gib dein Gewicht an
                          </div>
                        </div>
                      </div>
                    </template>
                  </opened-accordion>

                  <opened-accordion class="mb-2">
                    <template v-slot:title>
                      <span class="font-semibold text-l">Vorerkrankungen</span>
                    </template>
                    <template v-slot:content>
                      <div class="row">
                        <div class="col-12">
                          <label class="form-label"
                            >Ich habe einen Hallux Valgus</label
                          >
                          <el-popover
                            placement="top"
                            title=""
                            :width="250"
                            trigger="click"
                          >
                            <span> Ein Hallux Valgus ist eine Fehlstellung der großen Zehe, die das Laufen erschweren und schmerzen kann.</span
                            >
                            <template #reference>
                              <span class="ml-2 svg-display"
                                ><svg class="icon">
                                  <use
                                    xlink:href="../assets/info.svg#info"
                                  /></svg
                              ></span>
                            </template>
                          </el-popover>
                          <br />
                          <input
                            type="checkbox"
                            class="checkbox"
                            v-model="medical.leftValgus"
                          />
                          <label class="pl-2">links</label>
                          <input
                            type="checkbox"
                            class="checkbox ml-3"
                            v-model="medical.rightValgus"
                          />
                          <label class="pl-2">rechts</label>
                        </div>
                        <div class="col-12">
                          <label class="form-label"
                            >Ich habe einen Fersensporn</label
                          >
                          <el-popover
                            placement="top"
                            title=""
                            :width="250"
                            trigger="click"
                          >
                            <span>Ein Fersensporn ist eine dornartige Kalkablagerung oder Verknöcherung der Sehne. Er kann sich an der oberen oder unteren Ferse bilden.</span
                            >
                            <template #reference>
                              <span class="ml-2 svg-display"
                                ><svg class="icon">
                                  <use
                                    xlink:href="../assets/info-2.svg#info"
                                  /></svg
                              ></span>
                            </template>
                          </el-popover>
                          <br />
                          <input
                            type="checkbox"
                            class="checkbox"
                            v-model="medical.leftFersenporn"
                          />
                          <label class="pl-2">links</label>
                          <input
                            type="checkbox"
                            class="checkbox ml-3"
                            v-model="medical.rightFersenporn"
                          />
                          <label class="pl-2">rechts</label>
                        </div>
                        <div class="col-12">
                          <label class="form-label">Ich bin Diabetiker</label>
                          <br />
                          <input
                            type="checkbox"
                            class="checkbox"
                            v-model="medical.diabetic"
                          />
                          <label class="pl-2">ja</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label class="form-label"
                            >ich habe eine diagnostizierte Beinlängendifferenz oder eine andere relevante Diagnose</label
                          ><br />
                          <input
                            type="checkbox"
                            class="checkbox"
                            v-model="medicalCamera"
                          />
                          <span class="pl-2 terms"
                            >Wenn ja, lade bitte die Diagnose/Verordnung hoch bzw. mache ein Foto davon</span
                          >
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col text-center">
                          <div class="image-upload pb-2" v-if="medicalCamera">
                            <input
                              type="file"
                              hidden
                              name="file"
                              id="file"
                              class="inputfile"
                              accept="image/*"
                              @change="onChangeMedical"
                            />
                            <label for="file"
                              ><img class="camera" src="../assets/camera.svg"
                            />
                            <p class="camera-icon-text">Foto aufnehmen</p>
                            </label>
                          </div>
                          <div class="preview" v-if="model.medicalImageUrl">
                            <span
                              class="crosssign camera-close"
                              @click="showMedicalCamera"
                            >
                              <div class="crosssign_circle_red"></div>
                              <div class="crosssign_stem"></div>
                              <div class="crosssign_stem2"></div>
                            </span>
                            <img :src="model.medicalImageUrl" />
                          </div>
                          <div class="col-12">
                            <textarea
                              class="form-control"
                              rows="3"
                              placeholder="hier ist Platz für weitere relevante Informationen über dich (Schmerzen, Operationen etc.) "
                              v-model="medical.notes"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </template>
                  </opened-accordion>

                </div>
              </div>
            </section>
            <!-- Form 3 End-->
            
            <!-- Form 4 Start-->
            <section v-if="step == 3">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Fußfotos & Gangvideo</i></h2>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <p class="">
                    Es folgen nun 2 Fußfotos und ein Gangvideo. <br />
                    Damit du das im Handumdrehen schaffst, haben wir dir einen
                    Handyhalter mitgeschickt.
                  </p>
                </div>
              </div>

              <div class="row my-2">
                <div class="col-12" v-if="poseOneDone" ref="poseOne">
                  <h3 class="pl-2 pose-success">✔ Fußfoto 1 erledigt</h3>
                </div>
                <!--<div class="col-12" v-if="poseTwoDone" ref="poseTwo">
                  <h3 class="pl-2 pose-success">✔ Fußfoto 2 erledigt</h3>
                </div>-->
                <div class="col-12" v-if="poseThreeDone" ref="poseThree">
                  <h3 class="pl-2 pose-success">✔ Fußfoto 2 erledigt</h3>
                </div>
              </div>

              <div class="row my-2" v-if="phoneStand">
                <div class="col-12">
                  <h3 class="text-center mb-2">Handyhalter zusammenfalten</h3>
                  <figure class="media">
                    <video
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v5.jpg"
                    >
                      <source src="../assets/videos/2_Fussfotos_Handyhalter_v2.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
                <div class="col-12">
                  <h3 class="text-center mb-2">So stehst du richtig</h3>
                  <img src="../assets/images/v7.jpg" />
                </div>
                <div class="col-12 my-2">
                  <app-accordion class="mt-2">
                    <template v-slot:title>
                      <span class="font-semibold text-l"
                        >Hier Detailbeschreibung auklappen</span>
                    </template>
                    <template v-slot:content>
                      <ol>
                          <li>
                            Falte zuerst deinen Handyhalter laut Anleitung zusammen.
                          </li>
                          <li>
                            Stelle dein Smartphone in den Halter mit dem Display zu
                            dir gerichtet.
                          </li>
                          <li>
                            Bitte trage eine kurze Hose oder eine enge Leggings.
                            Wichtig ist, dass man auch deine Knie erkennen kann.
                          </li>
                          <li>Achte auf eine ausreichende Beleuchtung im Raum.</li>
                          <li>
                            Positioniere dich für die Fotos und das Video in ca. einen
                            Meter Abstand zum Handyhalter samt Smartphone.
                          </li>
                          <li>
                            Für die Fotos und das Video stehe und gehe ganz natürlich!
                          </li>
                          <li>
                            Keine Sorge, du kannst das Foto bzw. Video wiederholen,
                            wenn du mit dem Ergebnis nicht zufrieden bist.
                          </li>
                        </ol>
                    </template>
                  </app-accordion>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="poseOne"
                  />
                  <label class="pl-2"> Handyhalter aufgebaut</label>
                </div>
              </div>

              <div v-if="poseOne" class="row web-camera-container">
                <h2 class="text-left">Fußfoto 1</h2>
                <div class="row my-2">
                  <div class="col-lg-12">
                    <p>Bitte beachte die folgenden Dinge:</p>
                    <ol>
                      <li>
                        stehe ca. einen Meter von deinem Smartphone entfernt
                      </li>
                      <li>stehe natürlich und locker</li>
                      <li>deine Füße sollten komplett sichtbar sein</li>
                    </ol>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12">
                    <h3 class="text-center mb-2">
                      Fußfoto 1 - normaler Stand von vorne
                    </h3>
                    <figure class="media">
                      <video
                        width="320"
                        height="180"
                        controls
                        playsinline
                        poster="../assets/images/v6.jpg"
                      >
                        <source
                          src="../assets/videos/3_Fussfotos_Foto1.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </figure>
                  </div>
                </div>
                <div class="col-12">
                  <h3 class="text-center mb-2">So stehst du richtig</h3>
                  <img src="../assets/images/p16.jpg" />
                </div>
                <div class="col-12 my-2">
                  <p class="text-justify">
                    Nachdem du den Auslöser hier unten drückst, hast du 15
                    Sekunden Zeit um dich in Position zu bringen. <br />
                    Keine Sorge, du kannst das Foto wiederholen, wenn du mit dem
                    Ergebnis nicht zufrieden bist.
                  </p>
                </div>
                <div class="camera-button">
                    <span v-if="!isCameraOpen" @click="toggleCamera">
                      <label for="insole"><img class="camera" src="../assets/camera.svg" /></label>
                        <p class="camera-bottom-text">Foto aufnehmen</p>
                      </span>
                </div>

                <div
                  v-if="isCameraOpen"
                  class="camera-box"
                  :class="{ flash: isShotPhoto }"
                >
                  <h1 class="text-center clock">{{ countDown }}</h1>
                  <div
                    class="camera-shutter"
                    :class="{ flash: isShotPhoto }"
                  ></div>

                  <video
                    v-show="!isPhotoTaken"
                    class="img-padding"
                    ref="camera"
                    :width="450"
                    :height="337.5"
                    autoplay
                    playsinline
                  ></video>
                  <canvas
                    class="canvas-image"
                    v-show="isPhotoTaken"
                    ref="canvas"
                    :width="440"
                    :height="337.5"
                  ></canvas>
                </div>

                <div v-if="isPhotoTaken && isCameraOpen" class="row my-4">
                  <button
                    class="btn btn-replay"
                    type="button"
                    @click="replayPoseOne"
                  >
                    Nochmal
                  </button>
                  <button
                    class="btn btn-accept"
                    type="button"
                    @click="showPoseThree"
                  >
                    Speichern
                  </button>
                </div>
              </div>
              <div
                class="form-control-feedback col-12"
                v-if="attemptSubmitFive && !isPhotoTaken"
              >
                Bitte mache Foto 1
              </div>

              <div v-if="poseTwo" class="row web-camera-container">
                <h2 class="text-left">Fußfoto 2</h2>
                <div class="row my-2">
                  <div class="col-12">
                    <p>Bitte beachte die folgenden Dinge:</p>
                    <ol>
                      <li>
                        stehe ca. einen Meter von deinem Smartphone entfernt
                      </li>
                      <li>bringe Füße und Knie eng zusammen</li>
                      <li>
                        deine Füße und Knie sollten komplett sichtbar sein
                      </li>
                    </ol>
                  </div>
                </div>

                <div class="row my-2">
                  <div class="col-12">
                    <h3 class="text-center mb-2">
                      Fußfoto 2 - enger Stand von hinten
                    </h3>
                    <figure class="media">
                      <video
                        width="320"
                        height="180"
                        controls
                        playsinline
                        poster="../assets/images/v7.jpg"
                      >
                        <source
                          src="../assets/videos/4_Fussfotos_Foto2.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </figure>
                  </div>
                </div>

                <div class="col-12 img-padding">
                  <h3 class="text-center mb-2">So stehst du richtig</h3>
                  <img src="../assets/images/p17.jpg" />
                </div>
                <div class="col-12 my-2 img-padding">
                  <p class="text-justify">
                    Nachdem du den Auslöser hier unten drückst, hast du 15
                    Sekunden Zeit um dich in Position zu bringen. <br />
                    Keine Sorge, du kannst das Foto wiederholen, wenn du mit dem
                    Ergebnis nicht zufrieden bist.
                  </p>
                </div>
                <div>
                  <div class="row camera-button text-center">
                    <span v-if="!isSecondCameraOpen" @click="toggleSecondCamera">
                      <label for="insole"><img class="camera" src="../assets/camera.svg" /></label>
                      <p class="camera-bottom-text">Foto aufnehmen</p> 
                      </span>
                  </div>

                  <div
                    v-if="isSecondCameraOpen"
                    class="camera-box"
                    :class="{ flash: isSecondShotPhoto }"
                  >
                    <h1 class="text-center clock">{{ countDown }}</h1>
                    <div
                      class="camera-shutter"
                      :class="{ flash: isSecondShotPhoto }"
                    ></div>
                    <video
                      v-show="!isSecondPhotoTaken"
                      class="img-padding"
                      ref="secondCamera"
                      :width="450"
                      :height="337.5"
                      autoplay
                      playsinline
                    ></video>
                    <canvas
                      v-show="isSecondPhotoTaken"
                      class="canvas-image"
                      ref="secondCanvas"
                      :width="440"
                      :height="337.5"
                    ></canvas>
                  </div>

                  <div
                    v-if="isSecondPhotoTaken && isSecondCameraOpen"
                    class="camera-download"
                  >
                    <button
                      class="btn btn-replay"
                      type="button"
                      @click="replayPoseTwo"
                    >
                      Nochmal
                    </button>
                    <button
                      class="btn btn-accept"
                      type="button"
                      @click="showPoseThree"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </div>


              <div v-if="poseThree" class="row web-camera-container">
                <h2 class="text-left">Fußfoto 2</h2>
                <div class="row my-2">
                  <div class="col-12 img-padding">
                    <p>Bitte beachte die folgenden Dinge:</p>
                    <ol>
                      <li>
                        setze dich ca. einen Meter von deinem Smartphone
                        entfernt hin
                      </li>
                      <li>halte deine Fußsohlen in die Kamera</li>
                      <li>deine Fußsohlen sollten komplett sichtbar sein</li>
                    </ol>
                  </div>
                </div>

                <div class="row my-2">
                  <div class="col-12">
                    <h3 class="text-center mb-2">Fußfoto 2 - Fußsohlen</h3>
                    <figure class="media">
                      <video
                        width="320"
                        height="180"
                        controls
                        playsinline
                        poster="../assets/images/v8.jpg"
                      >
                        <source
                          src="../assets/videos/5_Fussfotos_Foto3.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </figure>
                  </div>
                </div>
                <div class="col-12 img-padding">
                  <h3 class="text-center mb-2">So sitzt du richtig</h3>
                  <img src="../assets/images/p18.jpg" />
                </div>
                <div class="col-12 my-2 img-padding">
                  <p class="text-justify">
                    Nachdem du den Auslöser hier unten drückst, hast du 15
                    Sekunden Zeit um dich in Position zu bringen. <br />
                    Keine Sorge, du kannst das Foto wiederholen, wenn du mit dem
                    Ergebnis nicht zufrieden bist.
                  </p>
                </div>
                <div>
                  <div class="camera-button">
                    <span v-if="!isThirdCameraOpen" @click="toggleThirdCamera">
                      <label for="insole"><img class="camera" src="../assets/camera.svg" /></label>
                      <p class="camera-bottom-text">Foto aufnehmen</p>
                      </span>
                  </div>

                  <div
                    v-if="isThirdCameraOpen"
                    class="camera-box"
                    :class="{ flash: isThirdShotPhoto }"
                  >
                    <h1 class="text-center clock">{{ countDown }}</h1>
                    <div
                      class="camera-shutter"
                      :class="{ flash: isThirdShotPhoto }"
                    ></div>

                    <video
                      v-show="!isThirdPhotoTaken"
                      class="img-padding"
                      ref="thirdCamera"
                      :width="450"
                      :height="337.5"
                      autoplay
                      playsinline
                    ></video>
                    <canvas
                      v-show="isThirdPhotoTaken"
                      class="canvas-image"
                      ref="thirdCanvas"
                      :width="440"
                      :height="337.5"
                    ></canvas>
                  </div>
                  <div
                    v-if="isThirdPhotoTaken && isThirdCameraOpen"
                    class="camera-download"
                  >
                    <button
                      class="btn btn-replay"
                      type="button"
                      @click="replayPoseThree"
                    >
                      Nochmal
                    </button>
                    <button
                      class="btn btn-accept"
                      type="button"
                      @click="showFootPoseImages"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="form-control-feedback col-12"
                v-if="attemptSubmitFive && !isThirdCameraOpen"
              >
                Bitte mache Foto 2
              </div>

              <div class="row" v-if="allImages">
                <base-alert type="success mt-3">
                  <span class="alert-inner--icon"
                    ><i class="ni ni-like-2"></i
                  ></span>
                  <span class="alert-inner--text"
                    ><strong>Success!</strong>Foot Pose Images are
                    uploaded!</span
                  >
                </base-alert>
              </div>

              <div class="row my-2" v-if="catWalkCamera">
                <h2 class="text-center pl-7">Gangvideo</h2>
                <div class="row my-2">
                  <div class="col-lg-12">
                    <p>So wird das Gangvideo perfekt:</p>
                    <ol>
                      <li>
                        drehe dich um und gehe 4-6 Schritte nach hinten, drehe dich wieder um und komme wieder auf die Kamera zu
                      </li>
                      <li>
                        deine Füße und Knie sollten jederzeit gut sichtbar sein
                      </li>
                    </ol>
                  </div>
                </div>

                <div class="col-12">
                  <h3 class="text-center mb-2">Gangvideo</h3>
                  <figure class="media">
                    <video
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v9.jpg"
                    >
                      <source src="../assets/videos/6_Fussfotos_Video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
                <div class="col-12">
                  <p class="text-justify">
                    Nachdem du den Auslöser hier unten drückst, wechsle auf die
                    Selfie-Kamera deines Smartphones und starte die
                    Videoaufnahme. Wenn du fertig bist, beende das Video.
                  </p>
                </div>
              </div>

              <div class="row my-2">
                <div class="col text-center">
                  <div class="image-upload pb-2" v-if="catWalkCamera">
                    <input
                      type="file"
                      hidden
                      name="catwalk"
                      id="catwalk"
                      class="inputfile"
                      accept="video/*"
                      @change="onChangeCatwalk"
                    />
                    <label for="catwalk"
                      ><img class="camera" src="../assets/video-camera.svg"
                    />
                    <p class="video-bottom-text">Video aufnehmen</p>
                    </label>
                  </div>

                  <figure class="media" v-if="model.catWalkVideoUrl">
                    <video
                      v-if="model.catWalkVideoUrl"
                      width="320"
                      height="180"
                      controls
                      playsinline
                    >
                      <source :src="model.catWalkVideoUrl" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <span
                      class="crosssign video-camera-close"
                      @click="showvideoCamera"
                    >
                      <div class="crosssign_circle_red"></div>
                      <div class="crosssign_stem"></div>
                      <div class="crosssign_stem2"></div>
                    </span>
                  </figure>
                </div>
              </div>
              <div
                class="form-control-feedback col-12"
                v-if="attemptSubmitFive && !model.catWalkVideoUrl"
              >
                Bitte mache das Gangvideo
              </div>
            </section>
            <!-- Form 4 End-->

            <!-- Form 5 Start-->
            <section v-if="step == 4">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Einlegesohlen Foto</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h3 class="text-center my-2">
                    Video Anleitung
                  </h3>
                  <figure class="media">
                    <video
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v4.jpg"
                    >
                      <source src="../assets/videos/7_Einlegesohlenfoto.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6">
                  <img src="../assets/images/p13.jpg" />
                </div>
                <div class="col-6">
                  <img src="../assets/images/p14.jpg" />
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <app-accordion class="mt-2">
                    <template v-slot:title>
                      <span class="font-semibold text-l"
                        >Hier Detailbeschreibung auklappen</span
                      >
                    </template>
                    <template v-slot:content>
                      <ol>
                        <li>
                          Nimm eine Einlegesohle (das Fußbett) oder Einlage deines Schuhs heraus und platziere diese
                          mittig auf das Raster.
                        </li>
                        <li>
                          Die Einlegesohle oder Einlage soll nach oben zeigen, so
                          wie sie auch im Schuh liegt.
                        </li>
                        <li>
                          Häufig sind die Sohlen leicht angehaftet, mit leichtem Zug
                          sollten diese entfernbar sein. Nur Mut.
                        </li>
                        <li>
                          Sollte das Fußbett jedoch gar nicht herausnehmbar sein,
                          platziere den ganzen Schuh mittig auf das Raster.
                        </li>
                        <li>
                          Fotografiere von oben und möglichst gerade aus der
                          Vogelperspektive.
                        </li>
                        <li>
                          Die drei Eckmarker sollten nahe am äußeren Rand sichtbar
                          sein.
                        </li>
                        <li>
                          Dokumentiere für jede bestellte fyss Einlage und Schuhtyp
                          mindestens eine Einlegesohle deiner Schuhe. Wähle diese
                          über das „+“ Symbol unten aus. Mit dem nochmaligen Drücken
                          des „+“ Symbols kannst du eine weitere Einlage hinzufügen.
                        </li>
                        <li>
                          Gib uns auch gerne Zusatzinformationen wie Marke, Modell
                          oder Schuhgröße (wenn nicht EU) bekannt.
                        </li>
                      </ol>
                    </template>
                  </app-accordion>
                </div>
              </div>
              <div class="row">
                <div class="col-12 py-2">
                  <h3 class="sole-text">Einlegesohle / Schuh Information</h3>
                  <img
                    src="../assets/plus.png"
                    class="ml-2 plus-image"
                    @click="addRow"
                  />
                </div>
              </div>

              <div v-if="soleAdd">
                <div v-for="(sole, index) in soles" :key="sole">
                  <div class="row">
                    <div class="col-6">
                      <label>Schuhtyp</label>
                    </div>
                    <div class="col-4">
                      <label>Größe</label>
                    </div>
                    <div class="col-1">
                      <span class="crosssign" @click="deleteRow(index)">
                        <div class="crosssign_circle_red"></div>
                        <div class="crosssign_stem"></div>
                        <div class="crosssign_stem2"></div>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <select class="form-control" v-model="sole.category">
                        <option value="" selected>Auswählen</option>
                        <option value="Business Schuh">Business Schuh</option>
                        <option value="Halbschuh">Halbschuh</option>
                        <option value="Heel">Heel</option>
                        <option value="Outdoorschuh">Outdoorschuh</option>
                        <option value="Sneaker">Sneaker</option>
                        <option value="Sportschuh">Sportschuh</option>
                        <option value="Stiefelette">Stiefelette</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <select class="form-control" v-model="sole.size">
                        <option value="">Auswählen</option>
                        <option v-for="size in sizes" :key="size" :value="size">
                          EU-{{ size }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-6">
                      <div class="image-upload pb-2" v-if="sole.insoleImage">
                        <h3 class="">Einlegesohle Fotos</h3>
                        <input
                          type="file"
                          hidden
                          name="insole"
                          multiple
                          id="insole"
                          class="inputfile"
                          accept="image/*"
                          @change="onChangeInsole($event, index)"
                        />
                        <label for="insole"
                          ><img class="camera" src="../assets/camera.svg"
                        />
                        <p class="camera-icon-text">Foto aufnehmen</p>
                        </label>
                      </div>

                      <div
                        v-for="(image, key) in sole.insoleImageUrl"
                        :key="image.id"
                      >
                        <div
                          class="insole-preview mb-3"
                          v-if="sole.insoleImageUrl"
                        >
                          <span
                            class="crosssign insole-camera-close"
                            @click="showInsoleCamera(key, index)"
                          >
                            <div class="crosssign_circle_black"></div>
                            <div class="crosssign_stem"></div>
                            <div class="crosssign_stem2"></div>
                          </span>
                          <img
                            :src="image"
                            v-bind:ref="'image' + parseInt(key)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <label>Weitere Details zu deinem Schuh</label>
                      <textarea
                        v-model="sole.notes"
                        class="form-control"
                        cols="5"
                        placeholder="Marke, Modell, US/UK Größe"
                      ></textarea>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitFour && missingSoleInformation"
                  >
                    Unter dem „+“ Symbol kannst du deine Einlegesohle bzw.
                    Schuhe dokumentieren. Bitte fülle alle angelegten Schuhtypen
                    vollständig aus!
                  </div>
                </div>
              </div>
            </section>
            <!-- Form 5 End-->

            <!-- Form 6 Start-->
            <section v-if="step == 6">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Blauabdruck</i></h2>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <p class="text-justify">
                    Bevor du startest sieh dir unsere Video- sowie Fotoanleitung
                    an! Sieh es dir bitte vorher genau an, da man den Abdruck
                    nur ein einziges Mal machen kann.
                    <br />Bitte beachte, dass das Kohlepapier abfärben kann.
                    Greife das Kohlepapier daher vorsichtig an und achte darauf,
                    wo du es nach dem Abreißen hinlegst.
                  </p>
                </div>
              </div>

              <div class="row my-2">
                <div class="col-12">
                  <h3 class="text-center mb-2">Video Anleitung</h3>
                  <figure class="media">
                    <video
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v3.jpg"
                    >
                      <source src="../assets/videos/8_Blauabdruck.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <h3 class="text-center">Bild-für-Bild Anleitung</h3>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <slider />
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <app-accordion class="mt-2">
                    <template v-slot:title>
                      <span class="font-semibold text-l"
                        >Hier Detailbeschreibung auklappen</span>
                    </template>
                    <template v-slot:content>
                        <ol>
                          <li>
                            Entferne nun vorsichtig die Schutzfolie und achte darauf,
                            dass das Kohlepapier darunter unversehrt bleibt.
                          </li>
                          <li>
                            Lasse die durchsichtige Folie langsam auf das
                            Kohlepapier herab.
                          </li>
                          <li>
                            Berühre das Kohlepapier nur mit deinen Füßen zum Zwecke des Abdrucks.
                          </li>
                          <li>
                            Stelle dich jetzt eng, seitlich neben die Blauabdruck-
                            Folie.
                          </li>
                          <li>
                            Steige nun mit deinem Fuß gerade und langsam seitlich auf die Folie.
                          </li>
                          <li>
                            Stehe gerade, belaste beide Beine gleichmäßig und zähle
                            bis drei.
                          </li>
                          <li>
                            Hebe nun behutsam die durchsichtige Folie gerade
                            und langsam wieder an. Verwende die farbige Lasche,
                            um das Kohlepapier zu entfernen. (Achtung kann
                            abfärben). Lasse die durchsichtige Folie, mit deinem
                            Abdruck, jetzt wieder langsam herab.
                          </li>
                          <li>Wiederhole den Vorgang mit deinem anderen Fuß.</li>
                        </ol>
                    </template>
                  </app-accordion>
                </div>
              </div>   
              <div class="row">
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.leftFootCamera"
                    @change="checkLeftFootCamera"
                  />
                  <label class="pl-2"> Blauabdruck links erledigt</label>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitThree && missingLeftFootPrint"
                  >
                    Bitte bestätige den erfolgreichen Blauabdruck links!
                  </div>
                </div>
              </div>

              <div class="row my-4" style="display: none">
                <div class="col text-center">
                  <div class="image-upload pb-2" v-if="leftFoot">
                    <h3 class="text-left">Left Foot Print Image</h3>
                    <input
                      type="file"
                      hidden
                      name="leftPrint"
                      class="inputfile"
                      accept="image/*"
                      @change="onChangeLeft"
                    />
                    <label
                      ><img class="camera" src="../assets/camera.png"
                    /></label>
                  </div>

                  <div class="preview" v-if="model.leftImageUrl">
                    <i
                      class="fa fa-times camera-close"
                      aria-hidden="true"
                      @click="showLeftFootCamera"
                    ></i>
                    <img :src="model.leftImageUrl" />

                    <base-alert type="success mt-3">
                      <span class="alert-inner--icon"
                        ><i class="ni ni-like-2"></i
                      ></span>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong>Left Foot Print Image
                        uploaded!</span
                      >
                    </base-alert>
                  </div>
                </div>
              </div>
              <div
                class="form-control-feedback"
                v-if="attemptSubmitThree && missingLeftFootImage"
              >
                Please Upload Left Foot Image.
              </div>

              <div class="row mb-4">
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.rightFootCamera"
                    @change="checkRightFootCamera"
                  />
                  <label class="pl-2"> Blauabdruck rechts erledigt</label>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitThree && missingRightFootPrint"
                  >
                    Bitte bestätige den erfolgreichen Blauabdruck rechts!
                  </div>
                </div>
              </div>

              <div class="row my-4" style="display: none">
                <div class="col text-center">
                  <div class="image-upload pb-2" v-if="rightFoot">
                    <h3 class="text-left">Right Foot Print Image</h3>
                    <input
                      type="file"
                      hidden
                      name="rightPrint"
                      id="rightPrint"
                      class="inputfile"
                      accept="image/*"
                      @change="onChangeRight"
                    />
                    <label for="rightPrint"
                      ><img class="camera" src="../assets/camera.png"
                    /></label>
                  </div>
                  <div class="preview" v-if="model.rightImageUrl">
                    <i
                      class="fa fa-times camera-close"
                      aria-hidden="true"
                      @click="showRightFootCamera"
                    ></i>
                    <img :src="model.rightImageUrl" />

                    <base-alert type="success mt-3">
                      <span class="alert-inner--icon"
                        ><i class="ni ni-like-2"></i
                      ></span>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong>Right Foot Print Image
                        uploaded!</span
                      >
                    </base-alert>
                  </div>
                </div>
              </div>
              <div
                class="form-control-feedback"
                v-if="attemptSubmitThree && missingRightFootImage"
              >
                Please Upload Right Foot Image.
              </div>
            </section>
            <!-- Form 6 End-->

            <!-- Form 7 Start-->
            <section v-if="step == 7">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Trittschaum-Abdruck</i></h2>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <p class="text-justify">
                    In diesem Video siehst du wie es funktioniert! Sieh es dir
                    bitte vorher genau an, da man den Abdruck nur ein einziges
                    Mal machen kann.
                  </p>
                </div>
              </div>

              <div class="row my-2">
                <div class="col-12">
                  <h3 class="text-center">Trittschaum Video Anleitung</h3>
                  <figure class="media">
                    <video
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v2.jpg"
                    >
                      <source src="../assets/videos/9_Schaumabdruck.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
              </div>
              <h3 class="text-center" ref="formTwo">
                Und so sollte das Ergebnis aussehen
              </h3>
              <div class="row my-2">
                <div class="col-12">
                  <img src="../assets/images/p5.jpg" />
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <app-accordion class="mt-2">
                    <template v-slot:title>
                      <span class="font-semibold text-l"
                        >Hier Detailbeschreibung auklappen</span
                      >
                    </template>
                    <template v-slot:content>
                      <ol>
                        <li>Lege den Trittschaum vor dir auf den Boden.</li>
                        <li>Deine Füße sollten barfuß, sauber und trocken sein.</li>
                        <li>Stelle dich eng, neben die Box.</li>
                        <li>Für dein Gleichgewicht, halte dich bei Bedarf an etwas fest.</li>
                        <li>
                          Steige nun mit dem Fuß gerade und langsam seitlich in den
                          Schaum.
                        </li>
                        <li>
                          Stehe gerade, belaste beide Beine gleichmäßig und zähle
                          bis drei.
                        </li>
                        <li>
                          Steige jetzt vorsichtig aus dem Schaum heraus und achte
                          darauf, dass der Trittschaum im Abdruckset bleibt.
                        </li>
                        <li>
                          Wiederhole den Abdruck für deinen anderen Fuß.
                        </li>
                        <li>
                          Falls etwas Trittschaum an den Füßen haften bleibt, wische
                          sie einfach mit einem Handtuch ab.
                        </li>
                      </ol>
                    </template>
                  </app-accordion>
                </div>
                <div class="col-12">
                  <p>
                    Sollte etwas schief gegangen sein, mache deinen Abdruck
                    Prozess fertig und kontaktiere dann bitte unseren
                    <a
                      :href="`mailto:help@fyss.com?subject=Hilfe Trittschaum - order #${this.emailOrderNumber}`"
                      >Support</a
                    >
                  </p>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.rightFoamPrint"
                    @change="checkRightFoamPrint"
                  />
                  <label class="pl-2">Schaumabdruck rechter Fuß erledigt</label>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitTwo && missingRightFoamPrint"
                  >
                    Bitte bestätige den erfolgreichen Schaumabdruck rechts!
                  </div>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.leftFoamPrint"
                    @change="checkLeftFoamPrint"
                  />
                  <label class="pl-2">Schaumabdruck linker Fuß erledigt</label>
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitTwo && missingLeftFoamPrint"
                  >
                    Bitte bestätige den erfolgreichen Schaumabdruck links!
                  </div>
                </div>
              </div>

              <div class="row my-4" style="display: none">
                <div class="col text-center">
                  <div class="image-upload pb-2" v-if="foamImage">
                    <h3 class="">Take Picture of Foam print</h3>
                    <input
                      type="file"
                      hidden
                      name="foamImage"
                      class="inputfile"
                      accept="image/*"
                      @change="onChange"
                    />
                    <label
                      ><img class="camera" src="../assets/camera.png"
                    /></label>
                  </div>
                  <div class="preview" v-if="model.imageUrl">
                    <i
                      class="fa fa-times camera-close"
                      aria-hidden="true"
                      @click="showFoamCamera"
                    ></i>
                    <img :src="model.imageUrl" />

                    <base-alert type="success mt-3">
                      <span class="alert-inner--icon"
                        ><i class="ni ni-like-2"></i
                      ></span>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong>Foam Print Image
                        uploaded!</span
                      >
                    </base-alert>
                  </div>
                </div>
              </div>
              <div
                class="form-control-feedback"
                v-if="attemptSubmitTwo && missingFoamImage"
              >
                Please Upload Foam Print Image.
              </div>
            </section>
            <!-- Form 7 End-->

            <!-- Form 8 start -->
            <section v-if="step == 8">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-center"><i>Retourversand</i></h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="text-justify">
                    Packe nun bitte den Klappfolder samt deiner Blauabdrücke
                    wieder in das Abdruckset und sende uns dein Abdruckset
                    retour. Der Handyhalter gehört nun dir.
                  </p>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <ul class="list-unstyled">
                    <li>
                      Die letzten Schritte:
                      <ul>
                        <li>
                          Verwende für den Rückversand, wenn möglich den
                          Versandkarton in der dein Abdruckset geliefert wurde.
                        </li>
                        <li>
                          Lege den Klappfolder samt der Blauabdrücke vorsichtig
                          wieder auf den Trittschaum zurück in die Box.
                        </li>
                        <li>
                          Verschließe nun deine Box und lege sie in den
                          Versandkarton zurück.
                        </li>
                        <li>
                          Klebe das mitgelieferte Retourenetikett auf den
                          Versandkarton.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <figure class="media">
                    <video
                      preload="metadata"
                      width="320"
                      height="180"
                      controls
                      playsinline
                      poster="../assets/images/v10.jpg"
                    >
                      <source src="../assets/videos/10_Einpacken.mp4" type="video/mp4" />

                      Your browser does not support the video tag.
                    </video>
                  </figure>
                </div>
              </div>

              <div class="row my-2">
                <div class="col-12 my-2">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.foamPrintBox"
                    @click="foamPrintBox"
                  />
                  <span class="pl-2"
                    >Der Trittschaum-Abdruck liegt in der Box.</span
                  >
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitSeven && missingFoamPrintBox"
                  >
                    Überprüfe, ob der Trittschaum-Abdruck in der Box ist.
                  </div>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="model.bluePrintsBox"
                    @click="bluePrintsBox"
                  />
                  <span class="pl-2"
                    >Der Klappfolder samt der Blauabdrücke liegt in der Box</span
                  >
                  <div
                    class="form-control-feedback"
                    v-if="attemptSubmitSeven && missingBluePrintsBox"
                  >
                    Übeprüfe ob der Klappfolder samt Blauabdrücke in der Box ist!
                  </div>
                </div>
              </div>
            </section>
            <!-- Form 8 End-->

            <div class="row my-2">
              <div class="col-12">
                <div class="text-center">
                  <button
                    v-if="step == 8"
                    @click.prevent="submit"
                    class="my-2 btn btn-md btn-next"
                  >
                    abschließen
                  </button>
                </div>

                <div class="text-center">
                  <button
                    v-if="step != totalSteps && step != 8"
                    @click.prevent="nextStep"
                    class="my-2 btn btn-md btn-next"
                  >
                    Weiter
                  </button>
                </div>
              </div>

              <div class="col-12 text-center">
                <button
                  v-if="step != 1 && step != totalSteps"
                  @click.prevent="prevStep"
                  class="my-2 btn btn-md btn-previous"
                >
                  Zurück
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppAccordion from "../components/AppAccordion";
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import { German } from 'flatpickr/dist/l10n/de.js';

import axios from "axios";
//import beep from "../assets/beep.mp3";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
export default {
  globalInjection: true,
  name: "register",
  data() {
    return {
      sizes: [
        36,
        36.5,
        37,
        37.5,
        38,
        38.5,
        39,
        39.5,
        40,
        40.5,
        41,
        41.5,
        42,
        42.5,
        43,
        43.5,
        44,
        44.5,
        45,
        45.5,
        46,
        46.5,
        47,
        47.5,
        48,
        48.5,
        49,
      ],
      consentModal:false,
      audio: new Audio(require('@/assets/beep.mp3')),
      //foot Pain point above classes
      rightInnenKnoechelFilled: "cls-1",
      rightAussenKnoechelFilled: "cls-1",
      rightZehenFilled: "cls-1",
      rightOberfussFilled: "cls-1",

      leftInnenKnoechelFilled: "cls-1",
      leftAussenKnoechelFilled: "cls-1",
      leftZehenFilled: "cls-1",
      leftOberfussFilled: "cls-1",
      //foot pain points below classes
      leftZehenBelowFilled: "cls-1",
      leftkleinerBallenFilled: "cls-1",
      leftGrosserBallenFilled: "cls-1",
      leftAussenRistFilled: "cls-1",
      leftInnenRistFilled: "cls-1",
      leftFerseFilled: "cls-1",

      rightZehenBelowFilled: "cls-1",
      rightkleinerBallenFilled: "cls-1",
      rightGrosserBallenFilled: "cls-1",
      rightAussenRistFilled: "cls-1",
      rightInnenRistFilled: "cls-1",
      rightFerseFilled: "cls-1",
      //Body pain point classes
      linkerFussFilled: "cls-1",
      linkerUnterschenkelFilled: "cls-1",
      linkesKnieFilled: "cls-1",
      linkerOberschenkelFilled: "cls-1",
      linkerArmFilled: "cls-1",

      rechterFussFilled: "cls-1",
      rechterUnterschenkelFilled: "cls-1",
      rechtesKnieFilled: "cls-1",
      rechterOberschenkelFilled: "cls-1",
      rechterArmFilled: "cls-1",

      huefteBeckenFilled: "cls-1",
      brustkorbFilled: "cls-1",
      kopfFilled: "cls-1",

      formOne: {
        orderNumber: "",
        terms: "",
        email: "",
      },
      formSix: {
        gender: "",
        birthday: "",
        shoeSize: "",
        weight: "",
        height: "",
      },
      config: {
        locale: German,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
        disableMobile: 'true',
      },
      medical: {
        leftValgus: "",
        rightValgus: "",
        leftFersenporn: "",
        rightFersenporn: "",
        diabetic: "",
        medicalImage: "",
        notes: "",
      },
      fussOben: {
        leftZehen: "",
        leftOberfuss: "",
        leftAussenKnoechel: "",
        leftInnenKnoechel: "",

        rightZehen: "",
        rightOberfuss: "",
        rightAussenKnoechel: "",
        rightInnenKnoechel: "",
        fussObenNotes: "",
      },
      fussUnten: {
        leftZehen: "",
        leftkleinerBallen: "",
        leftGrosserBallen: "",
        leftAussenRist: "",
        leftInnenRist: "",
        leftFerse: "",

        rightZehen: "",
        rightkleinerBallen: "",
        rightGrosserBallen: "",
        rightAussenRist: "",
        rightInnenRist: "",
        rightFerse: "",
        fussUntenNotes: "",
      },
      koerper: {
        linkerFuss: "",
        linkerUnterschenkel: "",
        linkesKnie: "",
        linkerOberschenkel: "",
        linkerArm: "",

        rechterFuss: "",
        rechterUnterschenkel: "",
        rechtesKnie: "",
        rechterOberschenkel: "",
        rechterArm: "",
        koerperNotes: "",
        huefteBecken: "",
        brustkorb: "",
        kopf: "",
      },
      formSeven: {
        foam: "",
        stand: "",
        folder: "",
      },
      emailOrderNumber:"",
      display: "flex",
      missingAllPacked: true,
      missingTerms: true,
      missingLeftFoamPrint: true,
      missingRightFoamPrint: true,
      missingFoam: true,
      missingFolder: true,
      missingStand: true,
      existingEmail: false,
      missingFoamPrint: true,
      missingFootBluePrint: true,
      missingLeftFootPrint: true,
      missingRightFootPrint: true,
      missingBluePrintsBox: true,
      missingFoamPrintBox: true,
      missingTimeCheck:true,
      missingbareFootCheck:true,
      missingCookiesCheck:true,
      attemptImpressionSet :false,
      model: {
        timeCheck:'',
        bareFootCheck:'',
        backgroundCheck:'',
        name: "",
        imageUrl: "",
        image: "",
        foamCamera: "",
        leftImageUrl: "",
        leftFootImage: "",
        rightImageUrl: "",
        rightFootImage: "",
        InsoleImageUrl: "",
        notes: "",
        catWalkVideoUrl: "",
        sports_shoes: "",
        business_shoes: "",
        hicking_shoes: "",
        sportsInsoleImageUrl: [],
        businessInsoleImageUrl: [],
        hickingInsoleImageUrl: [],
        medicalImageUrl: "",
        leftFootCamera: "",
        rightFootCamera: "",
        firstPoseImage: "",
        secondPoseImage: "",
        thirdPoseImage: "",
        foamPrintBox: "",
        bluePrintsBox: "",
        rightFoamPrint: "",
        leftFoamPrint: "",
      },
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isSecondCameraOpen: false,
      isSecondPhotoTaken: false,
      isSecondShotPhoto: false,
      isThirdCameraOpen: false,
      isThirdPhotoTaken: false,
      isThirdShotPhoto: false,
      link: "#",
      terms: "",
      foamImage: true,
      step: 1,
      totalSteps: 9,
      isDisabled: true,
      leftFoot: true,
      rightFoot: true,
      checked: false,
      sportsInsoleImage: true,
      businessInsoleImage: true,
      hickingInsoleImage: true,
      poseOneDone: false,
      poseTwoDone: false,
      poseThreeDone: false,
      phoneStand: true,
      myWindow: "",
      error: false,
      images: [],
      medicalCamera: false,
      catWalkCamera: false,
      soleAdd: false,
      loading:false,
      //Form 5 Foot poses
      countDown: 15,
      poseOne: "",
      poseTwo: false,
      poseThree: false,
      poseOneCamera: false,
      poseOneVideo: "",
      poseTwoCamera: false,
      poseTwoVideo: "",
      poseThreeCamera: false,
      poseThreeVideo: "",
      footPoseImages: [],
      allImages: false,
      //accordion
      accordionOne: false,
      accordionTwo: false,
      accordionThree: false,
      attemptSubmit: false,
      attemptSubmitTwo: false,
      attemptSubmitThree: false,
      attemptSubmitFour: false,
      attemptSubmitFive: false,
      attemptSubmitSix: false,
      attemptSubmitSeven: false,
      isActive: true,
      soles: [
        {
          category: "",
          size: "",
          notes: "",
          insoleImage: true,
          insoleImageUrl: [],
          insoleImages: [],
        },
      ],
      catWalkvideo: "",
    };
  },
  components: {
    AppAccordion,
    flatPickr
  },
  computed: {
    missingOrderNumber: function () {
      return this.formOne.orderNumber === "";
    },
    missingEmail: function () {
      return this.formOne.email === "";
    },
    //missingFoamImage: function () { return this.model.imageUrl === ''; },
    //missingLeftFootImage: function () { return this.model.leftImageUrl === ''; },
    //missingRightFootImage: function () { return this.model.rightImageUrl === ''; },
    missingGender: function () {
      return this.formSix.gender === "";
    },
    missingBirthday: function () {
      return this.formSix.birthday === "";
    },
    missingShoeSize: function () {
      return this.formSix.shoeSize === "";
    },
    missingHeight: function () {
      return this.formSix.height === "";
    },
    missingWeight: function () {
      return this.formSix.weight === "";
    },

    missingSoleInformation: function () {
      const hasElements = this.soles.length > 0;
      const elementsAreValid = this.soles.every(function (imageUrl) {
        return (
          imageUrl.insoleImageUrl.length > 0 &&
          imageUrl.category &&
          imageUrl.size
        );
      });
      return !hasElements || !elementsAreValid;
    },
  },
  mounted() {
    if (localStorage.getItem("step")) {
      return (this.step = localStorage.getItem("step"));
    } else {
      return (this.step = 1);
    }
  },

  methods: {
    addRow: function () {
      if (this.soleAdd == false) {
        this.soleAdd = true;
      } else {
        this.soles.unshift({
          category: "",
          size: "",
          notes: "",
          insoleImage: true,
          insoleImageUrl: [],
          insoleImages: [],
        });
      }
    },
    deleteRow(index) {
      this.soles.splice(index, 1);
    },
    nextStep: function () {
      if (this.step == 1) {
        if (this.validateFormOne()) {
          this.loading = true;
          var formData = new FormData();
          formData.append("order_number", this.formOne.orderNumber);
          formData.append("email", this.formOne.email);
          axios
            .post("form-one", formData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data.email) {
                this.existingEmail = true;
                return false;
              } else {
                localStorage["customer_id"] = response.data.data.customer_id;
                localStorage["order_id"] = response.data.data.id;
                localStorage["order_number"] = response.data.data.order_number;
                localStorage["step"] = 2;
                localStorage["status"] = response.data.data.status;
                this.step++;
                window.scrollTo(0, 0);
              }
            })
            .catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
              this.loading =  false;
              });
        } else {
          return false;
        }
      }else if (this.step == 2) {
        if(this.validateFormEight()){
          this.step++;
          localStorage["step"] = 3;
            window.scrollTo(0, 0);
        }else{
          return false;
        }
      }else if (this.step == 5) {
        if (this.validateFormSix()) {
          this.loading = true;
          var sixthForm = new FormData();
          sixthForm.append("order_id", localStorage["order_id"]);
          sixthForm.append("customer_id", localStorage["customer_id"]);
          sixthForm.append("gender", this.formSix.gender);
          sixthForm.append("birthday", this.formSix.birthday);
          sixthForm.append("shoeSize", this.formSix.shoeSize);
          sixthForm.append("weight", this.formSix.weight);
          sixthForm.append("height", this.formSix.height);
          //Medical Questions

          //sixthForm.append('medical',JSON.stringify(this.medical));
          sixthForm.append("diabetic", this.medical.diabetic);
          sixthForm.append("leftFersenporn", this.medical.leftFersenporn);
          sixthForm.append("rightFersenporn", this.medical.rightFersenporn);
          sixthForm.append("leftValgus", this.medical.leftValgus);
          sixthForm.append("rightValgus", this.medical.rightValgus);
          sixthForm.append("medicalImage", this.medical.medicalImage);
          sixthForm.append("medicalNotes", this.medical.notes);
          //Foot Pain Points : Above
          /** sixthForm.append("leftZehenAbove", this.fussOben.leftZehen);
          sixthForm.append("leftOberfuss", this.fussOben.leftOberfuss);
          sixthForm.append(
            "leftAussenKnoechel",
            this.fussOben.leftAussenKnoechel
          );
          sixthForm.append(
            "leftInnenKnoechel",
            this.fussOben.leftInnenKnoechel
          );
          sixthForm.append("rightZehenAbove", this.fussOben.rightZehen);
          sixthForm.append("rightOberfuss", this.fussOben.rightOberfuss);
          sixthForm.append(
            "rightAussenKnoechel",
            this.fussOben.rightAussenKnoechel
          );
          sixthForm.append(
            "rightInnenKnoechel",
            this.fussOben.rightInnenKnoechel
          );
          sixthForm.append("fussObenNotes", this.fussOben.fussObenNotes);

          // Foot Pain point : below
          sixthForm.append("leftZehenBelow", this.fussUnten.leftZehen);
          sixthForm.append(
            "leftkleinerBallen",
            this.fussUnten.leftkleinerBallen
          );
          sixthForm.append(
            "leftGrosserBallen",
            this.fussUnten.leftGrosserBallen
          );
          sixthForm.append("leftAussenRist", this.fussUnten.leftAussenRist);
          sixthForm.append("leftInnenRist", this.fussUnten.leftInnenRist);
          sixthForm.append("leftFerse", this.fussUnten.leftFerse);
          sixthForm.append("rightZehenBelow", this.fussUnten.rightZehen);
          sixthForm.append(
            "rightkleinerBallen",
            this.fussUnten.rightkleinerBallen
          );
          sixthForm.append(
            "rightGrosserBallen",
            this.fussUnten.rightGrosserBallen
          );
          sixthForm.append("rightAussenRist", this.fussUnten.rightAussenRist);
          sixthForm.append("rightInnenRist", this.fussUnten.rightInnenRist);
          sixthForm.append("rightFerse", this.fussUnten.rightFerse);
          sixthForm.append("fussUntenNotes", this.fussUnten.fussUntenNotes);
          // Body pain points
          sixthForm.append("linkerFuss", this.koerper.linkerFuss);
          sixthForm.append(
            "linkerUnterschenkel",
            this.koerper.linkerUnterschenkel
          );
          sixthForm.append("linkesKnie", this.koerper.linkesKnie);
          sixthForm.append(
            "linkerOberschenkel",
            this.koerper.linkerOberschenkel
          );
          sixthForm.append("linkerArm", this.koerper.linkerArm);
          sixthForm.append("rechterFuss", this.koerper.rechterFuss);
          sixthForm.append(
            "rechterUnterschenkel",
            this.koerper.rechterUnterschenkel
          );
          sixthForm.append("rechtesKnie", this.koerper.rechtesKnie);
          sixthForm.append(
            "rechterOberschenkel",
            this.koerper.rechterOberschenkel
          );
          sixthForm.append("rechterArm", this.koerper.rechterArm);
          sixthForm.append("huefteBecken", this.koerper.huefteBecken);
          sixthForm.append("brustkorb", this.koerper.brustkorb);
          sixthForm.append("kopf", this.koerper.kopf);
          sixthForm.append("koerperNotes", this.koerper.koerperNotes);
           **/
          axios
            .post("form-six", sixthForm, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log(response)
              if (response.data) {
                this.error = false;
                localStorage["step"] = 4;
                this.step++;
                window.scrollTo(0, 0);
              } else {
                return false;
              }
            })
            .catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
              this.loading =  false;
              });
        } else {
          return false;
        }
      } else if (this.step == 3) {
        if (this.validateFormFive()) {
          this.loading = true;
          var fifthForm = new FormData();
          fifthForm.append("firstPoseImage", this.model.firstPoseImage);
          //fifthForm.append("secondPoseImage", this.model.secondPoseImage);
          fifthForm.append("thirdPoseImage", this.model.thirdPoseImage);
          fifthForm.append("catWalk", this.catWalkvideo);
          fifthForm.append("customer_id", localStorage["customer_id"]);
          fifthForm.append("order_id", localStorage["order_id"]);
          axios
            .post("form-five", fifthForm, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data) {
                this.error = false;
                this.step++;
                window.scrollTo(0, 0);
                localStorage["step"] = 4;
              } else {
                return false;
              }
            })
            .catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
              this.loading =  false;
              });
        } else {
          return false;
        }
      } else if (this.step == 4) {
        if (this.validateFormFour()) {
          this.loading = true;
          var fourthForm = new FormData();
          fourthForm.append("order_id", localStorage["order_id"]);
          fourthForm.append("customer_id", localStorage["customer_id"]);
          for (let i = 0; i < this.soles.length; i++) {
            for (let key of Object.keys(this.soles[i])) {
              let value = this.soles[i][key];
              if (key === "insoleImages") {
                for (let j = 0; j < value.length; j++) {
                  const file = value[j];
                  fourthForm.append(`soles[${i}][${key}][${j}]`, file);
                }
              } else {
                fourthForm.append(`soles[${i}][${key}]`, value);
              }
            }
          }
          axios
            .post("form-four", fourthForm, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data) {
                this.error = false;
                this.step++;
                window.scrollTo(0, 0);
                localStorage["step"] = 5;
              } else {
                return false;
              }
            })
            .catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
              this.loading =  false;
              });
        } else {
          return false;
        }
      } else if (this.step == 6) {
        if (this.validateFormThree()) {
          this.loading = true;
          var thirdForm = new FormData();
          thirdForm.append("leftFootCamera", this.model.leftFootCamera);
          thirdForm.append("rightFootCamera", this.model.rightFootCamera);
          thirdForm.append("order_id", localStorage["order_id"]);
          thirdForm.append("customer_id", localStorage["customer_id"]);
          axios
            .post("form-three", thirdForm, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data) {
                localStorage["step"] = 6;
                this.error = false;
                this.emailOrderNumber = localStorage["order_number"];
                this.step++;
                window.scrollTo(0, 0);
              } else {
                return false;
              }
            })
            .catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
              this.loading =  false;
              });
        } else {
          return false;
        }
      } else if (this.step == 7) {
        if (this.validateFormTwo()) {
          this.loading = true;
          var secondForm = new FormData();
          secondForm.append("left_foam_print", this.model.leftFoamPrint);
          secondForm.append("right_foam_print", this.model.rightFoamPrint);
          secondForm.append("order_id", localStorage["order_id"]);
          secondForm.append("customer_id", localStorage["customer_id"]);
          axios
            .post("form-two", secondForm, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data.data) {
                localStorage["step"] = 7;
                this.step++;
                this.error = false;
                window.scrollTo(0, 0);
              } else {
                return false;
              }
            })
            .catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
              this.loading =  false;
              });
        } else {
          return false;
        }
      }
    },

    prevStep: function () {
      this.step--;
    },

    submit: function () {
      if (this.validateFormSeven()) {
        this.loading = true;
        var formSeven = new FormData();
        formSeven.append("order_id", localStorage["order_id"]);
        formSeven.append("customer_id", localStorage["customer_id"]);
        formSeven.append("bluePrintsBox", this.model.bluePrintsBox);
        formSeven.append("foamPrintBox", this.model.foamPrintBox);
        axios
          .post("form-seven", formSeven, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data) {
              this.step++;
              this.error = false;
              window.scrollTo(0, 0);
              this.display = "none";
              localStorage.clear();
            } else {
              return false;
            }
          }).catch((error) => {
              this.error = true;
              window.scrollTo(0, 100);
              throw error;
            }).finally(() => {
               this.loading =  false;
            });
      } else {
        return false;
      }
    },

    //Validate Form One
    validateFormOne: function () {
      this.attemptSubmit = true;
      if (this.missingOrderNumber || this.missingTerms || this.missingEmail) {
        return false;
      } else {
        return true;
      }
    },
    //Validate Form Two
    validateFormTwo: function () {
      this.attemptSubmitTwo = true;
      if (this.missingRightFoamPrint || this.missingLeftFoamPrint) {
        return false;
      } else {
        return true;
      }
    },

    //Validate Form three
    validateFormThree: function () {
      this.attemptSubmitThree = true;
      if (this.missingLeftFootPrint || this.missingRightFootPrint) {
        return false;
      } else {
        return true;
      }
    },
    //Validate Form Four
    validateFormFour: function () {
      this.attemptSubmitFour = true;
      if (this.missingSoleInformation) {
        return false;
      } else {
        return true;
      }
    },

    //Validate Form Five
    validateFormFive: function () {
      this.attemptSubmitFive = true;
      if (
        !this.isPhotoTaken ||
        //!this.isSecondPhotoTaken ||
        !this.isThirdPhotoTaken ||
        !this.model.catWalkVideoUrl
      ) {
        return false;
      } else {
        return true;
      }
    },
    //Validate Form Six
    validateFormSix: function () {
      this.attemptSubmitSix = true;
      if (
        this.missingGender ||
        this.missingBirthday ||
        this.missingShoeSize ||
        this.missingHeight ||
        this.missingWeight
      ) {
        if(this.$refs.AppAccordion.isOpen){
          window.scrollTo(0, 200);
        }else{
          this.$refs.AppAccordion.toggleAccordion();
          window.scrollTo(0, 200);
        }
        
        return false;
      } else {
        return true;
      }
    },
    //Validate Form Seven
    validateFormSeven: function () {
      this.attemptSubmitSeven = true;
      if (this.missingBluePrintsBox || this.missingFoamPrintBox) {
        return false;
      } else {
        return true;
      }
    },
    //Validate Form Eight
    validateFormEight: function () {
      this.attemptImpressionSet  = true;
      if (this.missingTimeCheck || this.missingbareFootCheck ||this.missingCookiesCheck) {
        return false;
      } else {
        return true;
      }
    },

    onChange(e) {
      const file = e.target.files[0];
      this.image = file;
      this.model.imageUrl = URL.createObjectURL(file);
      this.foamImage = false;
    },
    showFoamCamera() {
      this.foamImage = true;
      this.model.imageUrl = "";
      this.isDisabled = true;
    },
    onChangeLeft(e) {
      const file = e.target.files[0];
      this.model.leftFootImage = file;
      this.model.leftImageUrl = URL.createObjectURL(file);
      this.leftFoot = false;
    },
    showLeftFootCamera() {
      this.leftFoot = true;
      this.model.leftImageUrl = "";
    },
    onChangeRight(e) {
      const file = e.target.files[0];
      this.model.rightFootImage = file;
      this.model.rightImageUrl = URL.createObjectURL(file);
      this.rightFoot = false;
      this.isDisabled =
        this.model.rightImageUrl && this.model.leftImageUrl ? false : true;
    },
    showRightFootCamera() {
      this.rightFoot = true;
      this.model.rightImageUrl = "";
      this.isDisabled =
        this.model.rightImageUrl && this.model.leftImageUrl ? false : true;
    },

    onChangeCatwalk(e) {
      const file = e.target.files[0];
      this.catWalkvideo = file;
      this.model.catWalkVideoUrl = URL.createObjectURL(file);
      this.catWalkCamera = false;
      this.isDisabled = false;
    },
    showvideoCamera() {
      this.catWalkCamera = true;
      this.model.catWalkVideoUrl = "";
    },

    onChangeInsole(e, index) {
      var selectedFiles = e.target.files;
      for (var i = 0; i < selectedFiles.length; i++) {
        this.soles[index].insoleImageUrl.push(
          URL.createObjectURL(selectedFiles[i])
        );
        this.soles[index].insoleImages.push(selectedFiles[i]);
        this.soles[index].insoleImage = false;
      }
    },
    showInsoleCamera(key, index) {
      this.soles[index].insoleImageUrl.splice(key, 1);
      this.soles[index].insoleImages.splice(key, 1);
      if (this.soles[index].insoleImageUrl.length <= 0) {
        this.soles[index].insoleImage = true;
      }
    },

    onChangeMedical(e) {
      const file = e.target.files[0];
      this.medical.medicalImage = file;
      this.model.medicalImageUrl = URL.createObjectURL(file);
      this.medicalCamera = false;
    },
    showMedicalCamera() {
      this.medicalCamera = true;
      this.model.medicalImageUrl = "";
    },
    allPacked(e) {
      if (e.target.checked) {
        this.missingAllPacked = false;
      } else {
        this.missingAllPacked = true;
      }
    },

    timeCheck(e) {
      if (e.target.checked) {
        this.missingTimeCheck = false;
      } else {
        this.missingTimeCheck = true;
      }
    },
    bareFootCheck(e) {
      if (e.target.checked) {
        this.missingbareFootCheck = false;
      } else {
        this.missingbareFootCheck = true;
      }
    },
    backgroundCheck(e) {
      if (e.target.checked) {
        this.missingCookiesCheck = false;
      } else {
        this.missingCookiesCheck = true;
      }
    },
    termsCheck(e) {
      if (e.target.checked) {
        this.missingTerms = false;
      } else {
        this.missingTerms = true;
      }
    },
    checkRightFoamPrint(e) {
      if (e.target.checked) {
        this.missingRightFoamPrint = false;
      } else {
        this.missingRightFoamPrint = true;
      }
    },
    checkLeftFoamPrint(e) {
      if (e.target.checked) {
        this.missingLeftFoamPrint = false;
      } else {
        this.missingLeftFoamPrint = true;
      }
    },
    bluePrintsBox(e) {
      if (e.target.checked) {
        this.missingBluePrintsBox = false;
      } else {
        this.missingBluePrintsBox = true;
      }
    },
    foamPrintBox(e) {
      if (e.target.checked) {
        this.missingFoamPrintBox = false;
      } else {
        this.missingFoamPrintBox = true;
      }
    },
    checkFoamCamera(e) {
      if (e.target.checked) {
        this.missingFoamPrint = false;
      } else {
        this.missingFoamPrint = true;
      }
    },
    checkRightFootCamera(e) {
      if (e.target.checked) {
        this.missingRightFootPrint = false;
      } else {
        this.missingRightFootPrint = true;
      }
    },
    checkLeftFootCamera(e) {
      if (e.target.checked) {
        this.missingLeftFootPrint = false;
      } else {
        this.missingLeftFootPrint = true;
      }
    },
    checkBluePrint(e) {
      if (e.target.checked) {
        this.missingFootBluePrint = false;
      } else {
        this.missingFootBluePrint = true;
      }
    },

    //Form 5 Pose One Camera Settings and Image Capturing
    //Camera Open
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.startCameraStream();
      }
    },

    startCameraStream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          alert("Browser doesn't support or there is some errors." + error);
        });
      this.countDownTimer();
      setTimeout(() => {
        this.capture();
      }, 15000);
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      this.countDown = 15;
      tracks.forEach((track) => {
        track.stop();
      });
    },
    //Capture Pose One Image with 5 second of timer
    capture() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 5;

        setTimeout(() => {
          this.isShotPhoto = true;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      var canvas = this.$refs.canvas;
      var video = this.$refs.camera;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var cw=canvas.width;
      var ch=canvas.height;
      var vw=video.videoWidth;
      var vh=video.videoHeight;
      if(cw/ch<vw/vh){
        var th=cw*vh/vw;
        canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, 0, (ch-th)/2, cw, th);
      }else{
        var tw=ch*vw/vh;
        canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, (cw-tw)/2, 0, tw, ch);
      }

      this.model.firstPoseImage = this.$refs.canvas.toDataURL("image/png");
    },
    // Replay Pose One
    replayPoseOne() {
      this.isPhotoTaken = false;
      this.isCameraOpen = true;
      this.countDown = 15;
      this.countDownTimer();
      setTimeout(() => {
        this.capture();
      }, 15000);
    },

    // Replay Pose Two
    replayPoseTwo() {
      this.isSecondPhotoTaken = false;
      this.isSecondCameraOpen = true;
      this.countDown = 15;
      this.countDownTimer();
      setTimeout(() => {
        this.Secondcapture();
      }, 15000);
    },

    //Replay pose Three
    replayPoseThree() {
      this.isThirdPhotoTaken = false;
      this.isThirdCameraOpen = true;
      this.countDown = 15;
      this.countDownTimer();
      setTimeout(() => {
        this.thirdCapture();
      }, 15000);
    },

    //Second Pose Camera settings
    toggleSecondCamera() {
      if (this.isSecondCameraOpen) {
        this.isSecondPhotoTaken = false;
        this.isSecondCameraOpen = false;
        this.isSecondShotPhoto = false;
        this.stopSecondCameraStream();
      } else {
        this.isSecondCameraOpen = true;
        this.startSecondCameraStream();
      }
    },
    startSecondCameraStream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.secondCamera.srcObject = stream;
        })
        .catch((error) => {
          alert("Browser doesn't support or there is some errors." + error);
        });
      this.countDownTimer();
      setTimeout(() => {
        this.Secondcapture();
      }, 15000);
    },

    Secondcapture() {
      if (!this.isSecondPhotoTaken) {
        this.isSecondShotPhoto = true;

        const FLASH_TIMEOUT = 5;

        setTimeout(() => {
          this.isSecondShotPhoto = true;
        }, FLASH_TIMEOUT);
      }

      this.isSecondPhotoTaken = !this.isSecondPhotoTaken;
      var canvas = this.$refs.secondCanvas;
      var video = this.$refs.secondCamera;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var cw=canvas.width;
      var ch=canvas.height;
      var vw=video.videoWidth;
      var vh=video.videoHeight;
      if(cw/ch<vw/vh){
        var th=cw*vh/vw;
        canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, 0, (ch-th)/2, cw, th);
      }else{
        var tw=ch*vw/vh;
        canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, (cw-tw)/2, 0, tw, ch);
      }
      this.model.secondPoseImage = this.$refs.secondCanvas.toDataURL("image/png");
    },
    stopSecondCameraStream() {
      let tracks = this.$refs.secondCamera.srcObject.getTracks();
      this.countDown = 15;
      tracks.forEach((track) => {
        track.stop();
      });
    },
    //End Second Pose

    //Third Pose Camera settings
    toggleThirdCamera() {
      if (this.isThirdCameraOpen) {
        this.isThirdPhotoTaken = false;
        this.isThirdCameraOpen = false;
        this.isThirdShotPhoto = false;
        this.stopThirdCameraStream();
      } else {
        this.isThirdCameraOpen = true;
        this.startThirdCameraStream();
      }
    },
    startThirdCameraStream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.thirdCamera.srcObject = stream;
        })
        .catch((error) => {
          alert("Browser doesn't support or there is some errors." + error);
        });
      this.countDownTimer();
      setTimeout(() => {
        this.thirdCapture();
      }, 15000);
    },

    thirdCapture() {
      if (!this.isThirdPhotoTaken) {
        this.isThirdShotPhoto = true;

        const FLASH_TIMEOUT = 5;

        setTimeout(() => {
          this.isThirdShotPhoto = true;
        }, FLASH_TIMEOUT);
      }

      this.isThirdPhotoTaken = !this.isThirdPhotoTaken;
      var canvas = this.$refs.thirdCanvas;
      var video = this.$refs.thirdCamera;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      var cw=canvas.width;
      var ch=canvas.height;
      var vw=video.videoWidth;
      var vh=video.videoHeight;
      if(cw/ch<vw/vh){
        var th=cw*vh/vw;
        canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, 0, (ch-th)/2, cw, th);
      }else{
        var tw=ch*vw/vh;
        canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, (cw-tw)/2, 0, tw, ch);
      }
      this.model.thirdPoseImage = this.$refs.thirdCanvas.toDataURL("image/png");
    },
    stopThirdCameraStream() {
      let tracks = this.$refs.thirdCamera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    //End Third Pose

    //Generic methods of form 5
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          if(this.countDown<4){
            this.audio.play();
          }
          this.countDownTimer();
        }, 1000);
      }
    },
    showPoseOneCamera() {
      this.poseOneCamera = true;
    },
    showPoseTwo() {
      this.stopCameraStream();
      this.poseOne = false;
      this.poseOneDone = true;
      this.phoneStand = false;
      this.poseTwo = true;
      window.scrollTo(0, 800);
    },
    showPoseTwoCamera() {
      this.poseTwoCamera = true;
      this.countDown = 15;
    },
    showPoseThree() {
      this.stopCameraStream();
      this.poseOne = false;
      this.poseOneDone = true;
      this.phoneStand = false;
      this.poseThree = true;
      window.scrollTo(0, 800);
    },
    showPoseThreeCamera() {
      this.poseThreeCamera = true;
      this.countDown = 15;
    },

    showFootPoseImages() {
      this.stopThirdCameraStream();
      this.poseThree = false;
      this.poseThreeDone = true;
      //this.allImages = true;
      this.catWalkCamera = true;
      window.scrollTo(0, 800);
    },
    closeError() {
      this.error = false;
    },
    //Above Foot Pain points => Right
    rightInnenKnoechel() {
      if (this.rightInnenKnoechelFilled == "cls-1") {
        this.rightInnenKnoechelFilled = "red-color";
        this.fussOben.rightInnenKnoechel = 1;
      } else {
        this.rightInnenKnoechelFilled = "cls-1";
        this.fussOben.rightInnenKnoechel = "";
      }
    },
    rightAussenKnoechel() {
      if (this.rightAussenKnoechelFilled == "cls-1") {
        this.rightAussenKnoechelFilled = "red-color";
        this.fussOben.rightAussenKnoechel = 1;
      } else {
        this.rightAussenKnoechelFilled = "cls-1";
        this.fussOben.rightAussenKnoechel = "";
      }
    },
    rightOberfuss() {
      if (this.rightOberfussFilled == "cls-1") {
        this.rightOberfussFilled = "red-color";
        this.fussOben.rightOberfuss = 1;
      } else {
        this.rightOberfussFilled = "cls-1";
        this.fussOben.rightOberfuss = "";
      }
    },
    rightZehen() {
      if (this.rightZehenFilled == "cls-1") {
        this.rightZehenFilled = "red-color";
        this.fussOben.rightZehen = 1;
      } else {
        this.rightZehenFilled = "cls-1";
        this.fussOben.rightZehen = "";
      }
    },
    //Above Foot Pain points => Left
    leftInnenKnoechel() {
      if (this.leftInnenKnoechelFilled == "cls-1") {
        this.leftInnenKnoechelFilled = "red-color";
        this.fussOben.leftInnenKnoechel = 1;
      } else {
        this.leftInnenKnoechelFilled = "cls-1";
        this.fussOben.leftInnenKnoechel = "";
      }
    },
    leftAussenKnoechel() {
      if (this.leftAussenKnoechelFilled == "cls-1") {
        this.leftAussenKnoechelFilled = "red-color";
        this.fussOben.leftAussenKnoechel = 1;
      } else {
        this.leftAussenKnoechelFilled = "cls-1";
        this.fussOben.leftAussenKnoechel = "";
      }
    },
    leftOberfuss() {
      if (this.leftOberfussFilled == "cls-1") {
        this.leftOberfussFilled = "red-color";
        this.fussOben.leftOberfuss = 1;
      } else {
        this.leftOberfussFilled = "cls-1";
        this.fussOben.leftOberfuss = "";
      }
    },
    leftZehen() {
      if (this.leftZehenFilled == "cls-1") {
        this.leftZehenFilled = "red-color";
        this.fussOben.leftZehen = 1;
      } else {
        this.leftZehenFilled = "cls-1";
        this.fussOben.leftZehen = "";
      }
    },

    //Below Foot Pain points => Right
    rightFerse() {
      if (this.rightFerseFilled == "cls-1") {
        this.rightFerseFilled = "red-color";
        this.fussUnten.rightFerse = 1;
      } else {
        this.rightFerseFilled = "cls-1";
        this.fussUnten.rightFerse = "";
      }
    },
    rightAussenRist() {
      if (this.rightAussenRistFilled == "cls-1") {
        this.rightAussenRistFilled = "red-color";
        this.fussUnten.rightAussenRist = 1;
      } else {
        this.rightAussenRistFilled = "cls-1";
        this.fussUnten.rightAussenRist = "";
      }
    },
    rightInnenRist() {
      if (this.rightInnenRistFilled == "cls-1") {
        this.rightInnenRistFilled = "red-color";
        this.fussUnten.rightInnenRist = 1;
      } else {
        this.rightInnenRistFilled = "cls-1";
        this.fussUnten.rightInnenRist = "";
      }
    },
    rightGrosserBallen() {
      if (this.rightGrosserBallenFilled == "cls-1") {
        this.rightGrosserBallenFilled = "red-color";
        this.fussUnten.rightGrosserBallen = 1;
      } else {
        this.rightGrosserBallenFilled = "cls-1";
        this.fussUnten.rightGrosserBallen = "";
      }
    },
    rightkleinerBallen() {
      if (this.rightkleinerBallenFilled == "cls-1") {
        this.rightkleinerBallenFilled = "red-color";
        this.fussUnten.rightkleinerBallen = 1;
      } else {
        this.rightkleinerBallenFilled = "cls-1";
        this.fussUnten.rightkleinerBallen = "";
      }
    },
    rightZehenBelow() {
      if (this.rightZehenBelowFilled == "cls-1") {
        this.rightZehenBelowFilled = "red-color";
        this.fussUnten.rightZehen = 1;
      } else {
        this.rightZehenBelowFilled = "cls-1";
        this.fussUnten.rightZehen = "";
      }
    },
    //Below Foot Pain Points => Left

    leftFerse() {
      if (this.leftFerseFilled == "cls-1") {
        this.leftFerseFilled = "red-color";
        this.fussUnten.leftFerse = 1;
      } else {
        this.leftFerseFilled = "cls-1";
        this.fussUnten.leftFerse = "";
      }
    },
    leftAussenRist() {
      if (this.leftAussenRistFilled == "cls-1") {
        this.leftAussenRistFilled = "red-color";
        this.fussUnten.leftAussenRist = 1;
      } else {
        this.leftAussenRistFilled = "cls-1";
        this.fussUnten.leftAussenRist = "";
      }
    },
    leftInnenRist() {
      if (this.leftInnenRistFilled == "cls-1") {
        this.leftInnenRistFilled = "red-color";
        this.fussUnten.leftInnenRist = 1;
      } else {
        this.leftInnenRistFilled = "cls-1";
        this.fussUnten.leftInnenRist = "";
      }
    },
    leftGrosserBallen() {
      if (this.leftGrosserBallenFilled == "cls-1") {
        this.leftGrosserBallenFilled = "red-color";
        this.fussUnten.leftGrosserBallen = 1;
      } else {
        this.leftGrosserBallenFilled = "cls-1";
        this.fussUnten.leftGrosserBallen = "";
      }
    },
    leftkleinerBallen() {
      if (this.leftkleinerBallenFilled == "cls-1") {
        this.leftkleinerBallenFilled = "red-color";
        this.fussUnten.leftkleinerBallen = 1;
      } else {
        this.leftkleinerBallenFilled = "cls-1";
        this.fussUnten.leftkleinerBallen = "";
      }
    },
    leftZehenBelow() {
      if (this.leftZehenBelowFilled == "cls-1") {
        this.leftZehenBelowFilled = "red-color";
        this.fussUnten.leftZehen = 1;
      } else {
        this.leftZehenBelowFilled = "cls-1";
        this.fussUnten.leftZehen = "";
      }
    },

    //Body Pain points
    rechterFuss() {
      if (this.rechterFussFilled == "cls-1") {
        this.rechterFussFilled = "red-color";
        this.koerper.rechterFuss = 1;
      } else {
        this.rechterFussFilled = "cls-1";
        this.koerper.rechterFuss = "";
      }
    },
    linkerFuss() {
      if (this.linkerFussFilled == "cls-1") {
        this.linkerFussFilled = "red-color";
        this.koerper.linkerFuss = 1;
      } else {
        this.linkerFussFilled = "cls-1";
        this.koerper.linkerFuss = "";
      }
    },
    rechterUnterschenkel() {
      if (this.rechterUnterschenkelFilled == "cls-1") {
        this.rechterUnterschenkelFilled = "red-color";
        this.koerper.rechterUnterschenkel = 1;
      } else {
        this.rechterUnterschenkelFilled = "cls-1";
        this.koerper.rechterUnterschenkel = "";
      }
    },
    linkerUnterschenkel() {
      if (this.linkerUnterschenkelFilled == "cls-1") {
        this.linkerUnterschenkelFilled = "red-color";
        this.koerper.linkerUnterschenkel = 1;
      } else {
        this.linkerUnterschenkelFilled = "cls-1";
        this.koerper.linkerUnterschenkel = "";
      }
    },
    rechtesKnie() {
      if (this.rechtesKnieFilled == "cls-1") {
        this.rechtesKnieFilled = "red-color";
        this.koerper.rechtesKnie = 1;
      } else {
        this.rechtesKnieFilled = "cls-1";
        this.koerper.rechtesKnie = "";
      }
    },
    linkesKnie() {
      if (this.linkesKnieFilled == "cls-1") {
        this.linkesKnieFilled = "red-color";
        this.koerper.linkesKnie = 1;
      } else {
        this.linkesKnieFilled = "cls-1";
        this.koerper.linkesKnie = "";
      }
    },
    rechterOberschenkel() {
      if (this.rechterOberschenkelFilled == "cls-1") {
        this.rechterOberschenkelFilled = "red-color";
        this.koerper.rechterOberschenkel = 1;
      } else {
        this.rechterOberschenkelFilled = "cls-1";
        this.koerper.rechterOberschenkel = "";
      }
    },
    linkerOberschenkel() {
      if (this.linkerOberschenkelFilled == "cls-1") {
        this.linkerOberschenkelFilled = "red-color";
        this.koerper.linkerOberschenkel = 1;
      } else {
        this.linkerOberschenkelFilled = "cls-1";
        this.koerper.linkerOberschenkel = "";
      }
    },
    huefteBecken() {
      if (this.huefteBeckenFilled == "cls-1") {
        this.huefteBeckenFilled = "red-color";
        this.koerper.huefteBecken = 1;
      } else {
        this.huefteBeckenFilled = "cls-1";
        this.koerper.huefteBecken = "";
      }
    },
    brustkorb() {
      if (this.brustkorbFilled == "cls-1") {
        this.brustkorbFilled = "red-color";
        this.koerper.brustkorb = 1;
      } else {
        this.brustkorbFilled = "cls-1";
        this.koerper.brustkorb = "";
      }
    },
    rechterArm() {
      if (this.rechterArmFilled == "cls-1") {
        this.rechterArmFilled = "red-color";
        this.koerper.rechterArm = 1;
      } else {
        this.rechterArmFilled = "cls-1";
        this.koerper.rechterArm = "";
      }
    },
    linkerArm() {
      if (this.linkerArmFilled == "cls-1") {
        this.linkerArmFilled = "red-color";
        this.koerper.linkerArm = 1;
      } else {
        this.linkerArmFilled = "cls-1";
        this.koerper.linkerArm = "";
      }
    },
    kopf() {
      if (this.kopfFilled == "cls-1") {
        this.kopfFilled = "red-color";
        this.koerper.kopf = 1;
      } else {
        this.kopfFilled = "cls-1";
        this.koerper.kopf = "";
      }
    },
  },
};
</script>
<style>
video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
}
.insole-preview {
  position: relative;
}
.image-upload label {
  display: inline-block;
  text-align: center;
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}
.camera {
  width: 100px;
  height: 100px;
}

.preview img {
  border: 2px solid #84b2b9;
  border-radius: 8%;
  padding: 5px;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.insole-preview img {
  border: 2px solid #84b2b9;
  border-radius: 8%;
  padding: 5px;
  width: 100%;
  height: auto;
}

.camera-close {
  position: absolute !important;
  right: 14px !important;
  top: -5px !important;
  color: #f1f1f1 !important;
  background: black;
  padding: 8px;
  border-radius: 25px;
  opacity: unset !important;
}
.insole-camera-close {
  position: absolute !important;
  right: -5px !important;
  margin-top: -11px;
}
.video-camera-close {
  position: absolute !important;
  right: 6px !important;
  top: -12px !important;
}
.camera-close:hover,
.camera-close:focus {
  background-color: #000 !important;
}
.form-label {
  margin-top: 5px;
}
.form-title {
  margin-bottom: 10px;
}
@media only screen and (max-device-width: 780px) {
  .canvas-image {
    margin-left: unset !important;
  }
  .camera-download {
    margin-left: 4.5rem !important;
  }
}
@media only screen and (max-device-width: 375px) {
  .canvas-image {
    margin-left: unset !important;
  }
  .camera-download {
    margin-left: 3rem !important;
  }
}
@media only screen and (max-device-width: 320px) {
  .right-btn {
    margin-left: -10px;
  }
  .canvas-image {
    margin-left: unset !important;
  }
  .btn-next {
    width: 255px !important;
  }
  .btn-previous {
    width: 255px !important;
  }
  .camera-download {
    margin-left: 1.65rem !important;
  }
}
@media only screen and (max-device-width: 300px) {
  .canvas-image {
    margin-left: unset !important;
  }
  .btn-next {
    width: 223px !important;
  }
  .btn-previous {
    width: 223px !important;
  }
  .camera-download {
    margin-left: 0.5rem !important;
  }
}

@media only screen and (max-device-width: 250px) {
  .btn-next {
    width: 190px !important;
  }
  .btn-previous {
    width: 190px !important;
  }
}
.right-btn {
  margin-left: -10px;
}
.web-camera-container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}
.close-btn {
  margin-left: 44%;
}

.camera-box .camera-shutter {
  opacity: 0;
  background-color: #fff;
  position: absolute;
}
.camera-box .camera-shutter .flash {
  opacity: 1;
}

.camera-shoot {
  margin: 1rem 0;
}

.camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.camera-shoot img {
  height: 35px;
  object-fit: cover;
}

.camera-shoot-other {
  margin: 1rem 0;
  margin-left: 39%;
}
.camera-shoot-other button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.camera-shoot-other img {
  height: 35px;
  object-fit: cover;
}

.camera-download {
  margin-left: 5.5rem;
  margin-top: 2rem;
}

.camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}
.camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}
.loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}
.loader-circle :nth-child(2) {
  animation-delay: 0.2s;
}

.loader-circle :nth-child(3) {
  animation-delay: 0.4s;
}
.clock {
  transition: step-start;
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.camera-show {
  margin-left: 160%;
}

.message {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.message-header {
  cursor: pointer;
}
.message-body {
  padding: 0;
  max-height: 10em;
  overflow: hidden;
  transition: 0.3s ease all;
}
.is-closed .message-body {
  max-height: 0;
}
.message-content {
  padding: 20px;
}
.form-control-feedback {
  color: maroon;
  font-size: 14px;
}

.nott input[type="checkbox"] {
  display: contents;
}

input:checked ~ img {
  border: 2px solid #f6931d;
  padding: 2px;
  border-radius: 8px;
}
.color-boxes {
  width: 30px;
  height: 30px;
  padding: 2px;
}
.form-group {
  margin-bottom: 0.5rem !important;
}
.canvas-image {
  width: 100% !important;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.pose-success {
  color: #84b2b9;
}
.btn-next {
  width: 301px;
  height: 44px;
  background: #84b2b9 !important;
  border-color: #84b2b9 !important;
  border-radius: 31px !important;
  color: #fff !important;
  opacity: 1;
  font-size: 16px !important;
}
.btn-previous {
  width: 301px;
  height: 44px;
  border: 2px solid #2f2f2f !important;
  border-radius: 31px !important;
  opacity: 1;
  color: #2f2f2f !important;
  font-size: 16px !important;
}
.btn-accept {
  background: #84b2b9 !important;
  border-color: #84b2b9 !important;
  border-radius: 31px !important;
  color: #fff !important;
  opacity: 1;
  font-size: 16px !important;
}
.btn-replay {
  border: 2px solid #2f2f2f !important;
  border-radius: 31px !important;
  opacity: 1;
  color: #2f2f2f !important;
  font-size: 16px !important;
}

ol li {
  counter-increment: my-awesome-counter;
  margin: 0 0 8px 0;
}
ol li::before {
  content: counter(my-awesome-counter) ".  ";
  font-weight: bolder;
}
.exp-image {
  padding-left: unset !important;
  padding-right: unset !important;
}
.img-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.row-padding {
  padding-left: 34px !important;
  padding-right: 26px !important;
}
.box-list {
  cursor: pointer;
}
.crosssign {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
  transform: rotate(45deg);
}
.crosssign_plus {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  transform: rotate(90deg);
}

.crosssign_circle_red {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: red;
  border-radius: 11px;
  left: 0;
  top: 0;
}
.crosssign_circle_black {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: rgb(17, 16, 16);
  border-radius: 11px;
  left: 0;
  top: 0;
}
.crosssign_circle_green {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: rgb(23 167 66);
  border-radius: 14px;
  left: 0;
  top: 0;
}

.crosssign_stem,
.crosssign_stem2 {
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.crosssign_stem {
  width: 3px;
  height: 9px;
}

.crosssign_stem2 {
  width: 9px;
  height: 3px;
}
.icon {
  width: 1rem !important;
  height: 1rem !important;
}
.svg-display svg {
  display: inline !important;
}
.info-display img {
  display: inline !important;
}
.list-unstyled li ul {
  list-style-type: disc !important;
  margin-left: 20px;
}
.list-unstyled {
  margin-left: 12px;
}
ul li {
  margin: 0 0 6px 0;
}
.add-row {
  border: 2px dotted #84b2b9;
  border-radius: 19px;
  padding: 5px;
}
.plus-image {
  display: inline;
  cursor: pointer;
}
.sole-text {
  float: left;
  padding-top: 4px;
}
.unset-margin {
  margin: unset !important;
}
/* Fuss oben style */
#Oben .cls-1 {
  fill: #87c1c9;
}
.red-color {
  fill: red;
  cursor: pointer;
}
#Oben .cls-2,
.cls-3 {
  fill: none;
}
#Oben .cls-2 {
  stroke: #87c1c9;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-3 {
  stroke: #1d1d1b;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.cls-4 {
  font-size: 28px;
  fill: #1d1d1b;
  font-family: ArminGrotesk-UltraBold, Armin Grotesk;
  font-weight: bolder;
}
#Oben .cls-5 {
  letter-spacing: -0.01em;
}

/* Fuss unten style */
#Unten .cls-1 {
  fill: #87c1c9;
}
#Unten .cls-2,
.cls-3 {
  fill: none;
}
#Unten .cls-2 {
  stroke: #87c1c9;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dasharray: 3;
}
#Unten .cls-3 {
  stroke: #1d1d1b;
  stroke-linecap: round;
  stroke-linejoin: round;
}
#Unten .cls-4 {
  font-size: 28px;
  fill: #1d1d1b;
  font-family: ArminGrotesk-UltraBold, Armin Grotesk;
  font-weight: bolder;
}
#Unten .cls-5 {
  letter-spacing: -0.02em;
}
#Unten .cls-6 {
  letter-spacing: -0.01em;
}

#Koerper .cls-1 {
  fill: #87c1c9;
}
#Koerper .cls-2 {
  font-size: 28px;
  fill: #1d1d1b;
  font-family: ArminGrotesk-UltraBold, Armin Grotesk;
  font-weight: bolder;
}
#Koerper .cls-3 {
  letter-spacing: -0.01em;
}
#Koerper .cls-4 {
  letter-spacing: 0em;
}
#Koerper .cls-5 {
  letter-spacing: -0.01em;
}
#Koerper .cls-6,
.cls-7 {
  fill: none;
}
#Koerper .cls-6 {
  stroke: #87c1c9;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
#Koerper .cls-7 {
  stroke: #1d1d1b;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.loader{  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    background-image: url('../assets/loading.gif');
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
.camera-bottom-text{
  margin-top: -25px;
  margin-left: -12px;
  color: #000;
}
.camera-icon-text{
  margin-top: -18px;
  margin-left: -12px;
  color: #000;
}
.video-bottom-text{
  margin-top: -20px;
  margin-left: -14px;
  color: #000;
}
.modal-header{
   padding:unset !important;
}
.close > span:not(.sr-only) {
   font-size: 2rem !important;
   color:rgb(16, 16, 16) !important;
}
<!-- #birthday {
  font-size: 15px;
}

.input-birthday {
  font-size: 13px;
  border: 0;
  outline: 0;
  margin: 3px;
  color: black;
}

#day,
#month {
  width: 25px;
}

#year {
  width: 35px;
}
#birthday input::-webkit-outer-spin-button,
#birthday input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#birthday input[type=number] {
  -moz-appearance: textfield;
} -->
.top-header ul{
  list-style: disc;
  padding-left: 15px;
}
.top-header{
  text-align: left;
}
.el-popover{
  width: 100px !important;
}
.form-control[readonly] {
  background-color: #ffffff !important;
}
.flatpickr-calendar{
  padding: 2px 2px !important;
  width: 270px !important;
  font-size: 14px;
}
.flatpickr-calendar .flatpickr-day:hover, .flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover{
  background: #84b2b9 !important;
}
.flatpickr-calendar .flatpickr-day.today {
    border: 1px solid #84b2b9 !important;
}
.dayContainer {
    width: 270px;
    min-width: 270px;
    max-width: 270px;
}

</style>
